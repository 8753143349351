import React, { useEffect, useState, useRef } from "react";

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi'
import { readContract, writeContract } from '@wagmi/core'
import { ethers } from 'ethers';
import Canvas from './Canvas.js';
import md5 from 'crypto-js/md5';
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import { getRandomName } from "./helpers.js";

import contractABI from "./contracts/abi.json";

import './App.css';

window.Buffer = window.Buffer || require("buffer").Buffer; 

function Minting(props) {
    const [allowlist, setAllowlist] = useState(null);
    const [currentSupply, setCurrentSupply] = useState(0);
    const [tokensAdded, setTokensAdded] = useState([]);
    const [mintStatus, setMintStatus] = useState(null);
    const [isAllowlisted, setIsAllowlisted] = useState(false);
    const [remainingAllowlistMints, setRemainingAllowlistMints] = useState(null);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [showing, setShowing] = useState('generate');
    const [isDiceRotated, setIsDiceRotated] = useState(false);
    
    // Reference to our word input so we can focus it.
    const wordInputRef = useRef(null);
    const canvasRef = useRef(null);

    // Get their wallet address.
    const { address, isConnected } = useAccount();
    const walletAddress = (typeof address === 'string' ? address : '');
    let showAddress = (walletAddress.length > 0 && isConnected ? true : false);

    // Our supply.
    const maxSupply = Number(process.env.REACT_APP_MAX_SUPPLY);
    const percentage = (currentSupply / maxSupply) * 100;
    const isSoldOut = (currentSupply >= maxSupply ? true : false);

    useEffect(() => {
        fetchSupply();
        loadAllowlist();

        if (wordInputRef.current) {
            wordInputRef.current.focus();
        }

        return () => { };
    }, []);

    useEffect(() => {
        checkNewAddressForAllowlist(walletAddress);

        return () => {};
    }, [address]);

    useEffect(() => {
        return () => {};
    }, [tokensAdded]);

    const checkNewAddressForAllowlist = async (address) => {
        // Get their Merkle Proof.
        const proof = await isAddressAllowlisted(address);

        // Set their proof, will be false if not whitelisted, and an array if they are.
        setIsAllowlisted(proof);

        // If they're not on the whitelist, we don't need to get their remaining mints
        let allowedMints = 0;

        if (proof !== false) {
            allowedMints = await getRemainingAllowlistMintsByAddress(address);
        }

        setRemainingAllowlistMints(allowedMints);
    };

    const loadAllowlist = async (save) => {
        const response = await fetch('allowlist.json');
        const addresses = await response.json();

        setAllowlist(addresses);

        return addresses;
    };

    const isAddressAllowlisted = async (address) => {
        if (typeof address !== 'string' || address.length <= 0) {
            return false;
        }

        // Try to use the local allowlist.
        let list = allowlist;

        // Allowlist wasn't found, load it.
        if (allowlist === null) {
            list = await loadAllowlist();
        }

        const leaves = list.map(v => keccak256(v.toLowerCase()));
        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        const root = tree.getHexRoot();
        const leaf = keccak256(address.toLowerCase());
        const proof = tree.getHexProof(leaf);

        // console.log('Merkle Proof', proof);
        // console.log('Wallet List', list);

        console.log("isAddressAllowlisted", address.toLowerCase(), proof.length > 0 ? true : false)

        return (proof.length > 0 ? proof : false);
    };

    // return any invalid seeds
    const ensureAllSeedsAreValid = async (seeds) => {
        // const seedsToCheck = seeds.map((seed) => {
        //     return ethers.utils.formatBytes32String(seed);
        // });

        // const data = await readContract({
        //     address: process.env.REACT_APP_CONTRACT_ADDRESS,
        //     abi: contractABI,
        //     functionName: 'checkForBadSeeds',
        //     args: [seedsToCheck],
        // });

        // return data;

        return [];
    };

    const getRemainingAllowlistMintsByAddress = async (address) => {
        // const data = await readContract({
        //     address: process.env.REACT_APP_CONTRACT_ADDRESS,
        //     abi: contractABI,
        //     functionName: 'getRemainingAllowlistMints',
        // });

        // return Number(data);

        return Number(0);
    }

    const fetchSupply = async () => {
        // const data = await readContract({
        //     address: process.env.REACT_APP_CONTRACT_ADDRESS,
        //     abi: contractABI,
        //     functionName: 'totalSupply',
        // });
        
        // setCurrentSupply(Number(data));
        // return Number(data);

        setCurrentSupply(2500);
        return Number(2500);
    }

    const mintSeeds = async () => {
        const mintAmount = tokensAdded.length;
        const totalGasLimit = String(285000);
        const seeds = tokensAdded.map((word) => wordToSeed(word));
        const checkForBadSeeds = await ensureAllSeedsAreValid(seeds);

        // If we have any seeds that already exist, we can't mint.
        if (checkForBadSeeds.length > 0) {
            console.log("one or more failed");
            console.log(checkForBadSeeds);

            return
        }

        // Determine the cost of the minting.
        const costAfterAllowlist = getCost(remainingAllowlistMints);

        console.log(`Minting ${mintAmount}, Wei: ${costAfterAllowlist[0]}, ETH: ${costAfterAllowlist[1]}, Gas Limit: ${totalGasLimit}`);

        setMintStatus('minting');

        setTimeout(function () {
            setMintStatus('minted');
            setShowing('minted');
            props.onMint(mintAmount);
        }, 2500);

        // try {
        //     const { hash } = await writeContract({
        //         address: process.env.REACT_APP_CONTRACT_ADDRESS,
        //         abi: contractABI,
        //         functionName: 'mint',
        //         args: [seeds, (isAllowlisted === false ? [] : isAllowlisted)],
        //         account: walletAddress,
        //         value: totalCostWei,
        //     });

        //     props.onMint(mintAmount);
        // }
        // catch(e) {
        //     console.log("This txn failed. Error: ", e);
        // }
    };

    const numberWithCommas = (x) => {
        if (typeof x === 'undefined' || x == null) return 0;

        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [usingWord, setUsingWord] = useState('your words make your mask');

    const wordToSeed = (str) => {
        let num = BigInt(0);
        const base = BigInt(256);
        for (let i = 0; i < str.length; i++) {
            num = num * base + BigInt(str.charCodeAt(i));
        }
        console.log("WORD2SEED", num.toString());
        return num.toString();
    }

    const getCost = (freeMintsRemaining) => {
        const costPerToken = ethers.BigNumber.from(process.env.REACT_APP_COST_IN_WEI);
        let totalMinting = tokensAdded.length;

        if (freeMintsRemaining > 0) {
            totalMinting = totalMinting - freeMintsRemaining;

            if (totalMinting < 0) {
                totalMinting = 0;
            }
        }

        const totalCostInWei = costPerToken.mul(totalMinting);
        const totalCostInEth = ethers.utils.formatEther(totalCostInWei);

        return [totalCostInWei, totalCostInEth];
    };

    const tokenCount = tokensAdded.length;
    const hasMinted = mintStatus === 'minted';
    const disableFinalButtons = tokenCount === 0 || mintStatus === 'minting';

    // Have they added the maximum amount of tokens allowed?
    const hasAddedMax = tokenCount >= process.env.REACT_APP_MAX_MINT;
    const hasFreeMintsRemaining = remainingAllowlistMints !== null && remainingAllowlistMints > 0;
    const disableCartButtons = hasAddedMax || mintStatus === 'minting';

    // Choose the cost we'll display. If they have free mints, we'll show the original cost with a strikethrough
    const costBasedOnTotal = getCost(0);
    const costAfterAllowlist = getCost(remainingAllowlistMints);
    
    let displayableCost = (tokenCount > 0 ? `Mint ${tokenCount} for ${costBasedOnTotal[1]}` : "Mint your Bitmasks");

    if (tokenCount > 0 && remainingAllowlistMints > 0) {
        displayableCost = (
            <>
                <span>Mint {tokenCount} for </span>
                <span style={{ textDecoration: 'line-through' }}>{costBasedOnTotal[1]}</span>
                <span>&nbsp;{costAfterAllowlist[1] == 0 ? "free" : costAfterAllowlist[1]}</span>
                <span style={{ display: 'block', fontWeight: 'normal', marginTop: '1px', fontSize: '0.8rem' }}>({remainingAllowlistMints} free mints remaining)</span>
            </>
        );
    }

    // Do we show carousel buttons?
    const showCarouselLeftButton = carouselIndex > 0;
    const showCarouselRightButton = carouselIndex < tokenCount - 1;

    // Have they already added the current word to their tokens?
    const addedCurrentWord = tokensAdded.includes(usingWord);

    return (
        <>
            {showing === 'generate' && (
                <>
                    <div className="LiveSelection">
                        <div className="LiveSelectionWrapper">
                            <div className="LiveInput">
                                <div className="LiveInputGroup">
                                    <input 
                                        type="text" 
                                        ref={wordInputRef}
                                        value={usingWord} 
                                        onChange={(e) => { setUsingWord(e.target.value); }} />
                                    <img 
                                        className={`${isDiceRotated ? 'Rotated' : ''}`}
                                        src="dice_4.png" 
                                        onClick={() => { 
                                            setUsingWord(getRandomName());
                                            setIsDiceRotated(!isDiceRotated);
                                        }} />
                                </div>
                                {/* <div className="LiveInputSeed">seed #{wordToSeed(usingWord)}</div> */}
                            </div>
                        </div>
                        <div className="LiveCanvas" style={{ display: 'flex', flexDirection: 'row' }}>
                            <Canvas seed={wordToSeed(usingWord)} />
                        </div>
                        <div className="LiveSelectionProceed">
                            {addedCurrentWord ? 
                                (<button
                                    disabled={disableCartButtons}
                                    className="Remove"
                                    onClick={() => { 
                                        setTokensAdded(tokensAdded.filter((item) => item !== usingWord))
                                        setUsingWord(getRandomName()); 
                                    }}>Remove From Cart</button>) : 
                                (<button 
                                    disabled={disableCartButtons}
                                    className="Add"
                                    onClick={() => { 
                                        setTokensAdded([...tokensAdded, usingWord]); 
                                        setUsingWord(getRandomName()); 
                                    }}>
                                    <span>Add To Cart</span>
                                    <img 
                                        className={(disableCartButtons ? '' : 'bounce')} 
                                        src="arrow_down.png" />
                                </button>)}
                        </div>
                        <div className={`LiveScroller ${tokenCount > 0 ? 'Populated' : ''}`}>
                            {tokensAdded.map((word, i) => {
                                const seed = wordToSeed(word);

                                return (
                                    <div className="ScrollItem" key={i} onClick={() => { setUsingWord(word); }}>
                                        <Canvas seed={seed} scale={2} padding={0} />
                                        <div>
                                            <span className="Word">{word}</span>
                                            {/* <span className="Seed">seed #{seed}</span> */}
                                        </div>
                                        {mintStatus !== 'minting' && (
                                            <span 
                                                className="Remove"
                                                onClick={(e) => {
                                                    setTokensAdded(tokensAdded.filter((item) => item !== word));
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}>x</span>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        {tokenCount > 0 && (
                            <div className="LiveSelectionProceed">
                                {showAddress === false ?
                                    (<div className="WalletConnect"><ConnectButton theme="dark" /></div>) :
                                    (<button
                                        disabled={disableFinalButtons}
                                        className="Button Mint"
                                        onClick={() => { mintSeeds(); }}>{displayableCost}</button>)}
                            </div>
                        )}
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="ProgressBar">
                            <div className="ProgressBarWrapper">
                                <div className={`ProgressBarPercentage ${isSoldOut ? "soldOut" : ''}`} style={{ width: percentage + "%" }}></div>
                            </div>
                        </div>
                        <div className="ProgressBarText">{numberWithCommas(currentSupply)} / {numberWithCommas(process.env.REACT_APP_MAX_SUPPLY)} minted</div>
                    </div>
                </>
            )}

            <div className="MintingSection">

                {showing === 'minted' && (
                    <div className="MintChosen">
                        <div className="MintChosenHeading">{(carouselIndex + 1)} / {tokensAdded.length}</div>
                        <div className="MintChosenItem">
                            <div className="MintChosenItemWord">{tokensAdded[carouselIndex]}</div>
                            <div className="MintChosenItemSeed">seed #{wordToSeed(tokensAdded[carouselIndex])}</div>
                        </div>
                        <div className="MintChosenCarousel">
                            <div 
                                className={`MintChosenArrow ${!showCarouselLeftButton ? 'Disabled' : ''}`}
                                onClick={() => {
                                    if (showCarouselLeftButton) {
                                        setCarouselIndex(carouselIndex - 1);
                                    }
                                }}>
                                <img src="left.png" />
                            </div>
                            <Canvas seed={wordToSeed(tokensAdded[carouselIndex])} />
                            <div
                                className={`MintChosenArrow ${!showCarouselRightButton ? 'Disabled' : ''}`}
                                onClick={() => { 
                                    if (showCarouselRightButton) {
                                        setCarouselIndex(carouselIndex + 1);
                                    }
                                }}>
                                <img src="right.png" />
                            </div>
                        </div>
                        <div className="MintChosenButtons">
                            <a
                                className="Button Tweet"
                                target="_new"
                                href={`https://twitter.com/intent/tweet?url=https://bitmasks.xyz/image/1790466446&text=I%20just%20made%20my%20own%20Bitmask%20at%20https://bitmasks.xyz`}>Share Tweet</a>
                            <button
                                className="Button More"
                                onClick={() => {
                                    setMintStatus(null);
                                    setCarouselIndex(0);
                                    setShowing('generate');
                                    setTokensAdded([]);
                                }}>Mint more</button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Minting;
